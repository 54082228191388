$(function () {
  $(".clickable-row").click(function () {
    window.document.location = $(this).data("href");
  });

  $('.fromDate, .toDate').pickadate({
    format: 'dd.mm.yyyy',
    selectYears: true,
    selectMonths: true,
    selectYears: 40,
    min: [1996, 1, 1]
  })


  $('select[multiple]').chosen({
    width: "100%"
  });

  $('select.choosen').chosen({ disable_search: true });

  $('.fancybox').fancybox();

  $('.slimmenu').slimmenu({
    resizeWidth: '100000',
    collapserTitle: ' ',
    animSpeed: 300,
    easingEffect: null,
    indentChildren: false,
    childrenIndenter: '&nbsp;'
  });

  $('.minigrid').click(function () {
    $(".collapse-button").trigger("click");
  });


  $('form.contact select').on('change', function () {
    $('.form-group .parsley-errors-list').remove();
  });

  if (document.getElementById('statusbericht'))
    new Tablesort(document.getElementById('statusbericht'));
  // console.log(document.getElementById('statusbericht'));
});

